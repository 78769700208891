<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('TelemarketingCheckListQuestions')"
    @cancel="onCancel('TelemarketingCheckListQuestions')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TelemarketingTasksRejectionReasonCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TelemarketingCheckListQuestions',
      pageHeader: 'Создание вопроса для чек-листа по телемаркетингу',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Вопросы для чек-листа по телемаркетингу',
          route: { name: 'TelemarketingCheckListQuestions' },
        },
        {
          text: 'Создание вопроса для чек-листа по телемаркетингу',
        },
      ],
      initialForm: {
        name: null,
      },
      form: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'name', label: 'Название' }];

      const header = this.validateForm(this.form, mandatoryFields);
      if (header) {
        this.$notify({
          header,
          type: 'error',
          timer: 5000,
        });
        return;
      }

      this.isLoadingPage = true;

      this.createItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({ name: route });
          }
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    },
  },
};
</script>
